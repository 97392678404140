<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-card>
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex align-items-center justify-content-end m-2">
            <b-button
              v-b-modal.modal-center
              variant="primary"
            >
              <span class="text-nowrap">Add Category</span>
            </b-button>
            <!-- modal Add Language -->
            <b-modal
              v-if="show"
              id="modal-center"
              #default="{ hide }"
              centered
              title="Add Category"
              hide-footer
            >
              <b-form
                ref="myForm"
                class="p-2"
                @submit.enter.prevent=""
              >
                <b-form-group
                  label="Category Name"
                  label-for="cat_name"
                >
                  <b-form-input
                    id="cat_name"
                    v-model="addCat.category_name"
                    autofocus
                    placeholder="Category Name"
                  />
                </b-form-group>
                <b-form-group
                  label="Category Status"
                  label-for="cat_status"
                >
                  <b-form-select
                    id="cat_status"
                    v-model="addCat.status"
                    :options="statusOptions"
                    value-field="value"
                    text-field="text"
                  />
                </b-form-group>
                <b-form-group
                  label-for="cat_name"
                >
                  <b-form-file
                    ref="file"
                    name="icon"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Pick an Icon"
                    prepend-icon="mdi-camera"
                    @change="onChange"
                  />
                </b-form-group>
                <b-form-group
                  label="Color"
                  label-for="color"
                >
                  <b-form-input
                    id="color"
                    v-model="addCat.color"
                    autofocus
                    placeholder="Color"
                  />
                </b-form-group>
                <b-form-group
                  label="Description"
                  label-for="desc"
                >
                  <b-form-input
                    id="desc"
                    v-model="addCat.description"
                    autofocus
                    placeholder="Description"
                  />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    @click="addDonationCat()"
                  >
                    Add
                  </b-button>
                  <b-button
                    type="button"
                    variant="outline-secondary"
                    @click="hide"
                  >
                    Cancel
                  </b-button>
                </div>
              </b-form>
            </b-modal>
          </div>
          <b-table
            ref="categoryTable"
            :items="myProvider"
            :fields="donationTableColumns"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
          >
            <template #cell(id)="data">
              <span> # {{ data.item.id }} </span>
            </template>
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="data.item.icon"
                    :text="avatarText(data.item.category_name)"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.category_name }}
                </span>
              </b-media>
            </template>
            <template #cell(description)="data">
              <span> {{ data.item.description }}</span>
            </template>
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                <span> {{ resolveUserStatusValue(data.item.status) }}</span>
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <b-button
                v-b-modal="'modal-edit'"
                variant="outline-primary"
                class="btn-icon m-1"
                md
                user="'data.item.id'"
                @click="getCat(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                class="btn-icon"
                md
                @click="deleteCategory(data.item.id)"
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </template>
          </b-table>
          <!-- Edit -->
          <b-modal
            v-if="show"
            id="modal-edit"
            #default="{ hide }"
            centered
            title="Edit Category"
            hide-footer
          >
            <b-form
              ref="myForm"
              class="p-2"
              @submit.enter.prevent=""
            >
              <b-form-group
                label="Category Name"
                label-for="cat_name"
              >
                <b-form-input
                  id="cat_name"
                  v-model="catData.category_name"
                  autofocus
                  placeholder="Category Name"
                />
              </b-form-group>
              <b-form-group
                label="Category Status"
                label-for="cat_status"
              >
                <b-form-select
                  id="cat_status"
                  v-model="catData.status"
                  :options="statusOptions"
                  value-field="value"
                  text-field="text"
                />
              </b-form-group>
              <b-form-group
                label-for="cat_name"
              >
                <b-form-file
                  ref="file"
                  name="icon"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pick an Icon"
                  prepend-icon="mdi-camera"
                  @change="onChange"
                />
              </b-form-group>
              <b-form-group
                label="Color"
                label-for="color"
              >
                <b-form-input
                  id="color"
                  v-model="catData.color"
                  autofocus
                  placeholder="Color"
                />
              </b-form-group>
              <b-form-group
                label="Description"
                label-for="desc"
              >
                <b-form-input
                  id="desc"
                  v-model="catData.description"
                  autofocus
                  placeholder="Description"
                />
              </b-form-group>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  @click="updateCat(catData.id)"
                >
                  save
                </b-button>
                <b-button
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </b-modal>
        </b-col>
      </b-card>
    </b-row>
    <template>
      <b-row>
        <b-col md="6">
          <b-pagination
            :value="1"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-0 mt-1"
            prev-class="prev-item"
            next-class="next-item"
            @input="currentPage = $event"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>

import {
  BCard, BCol, BRow, BTable, BButton, VBModal, BForm, BFormInput, BFormGroup, BPagination, BMedia, BAvatar, BBadge, BFormSelect, BFormFile,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import axios from 'axios'
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, onUnmounted, reactive,
} from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import others from '../others'
import otherModule from '../OtherModule'

Vue.use(VueSweetalert2)

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BTable,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BPagination,
    BMedia,
    BAvatar,
    BBadge,
    BFormSelect,
    BFormFile,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selectedLanguage: '',
      rows: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      searchQuery: '',
      selectedId: 0,
      showEdit: false,
      loading: false,
      addCat: {
        category_name: null,
        status: null,
        icon: null,
        color: null,
        description: null,
      },
      statusOptions: [
        { value: '1', text: 'Active' },
        { value: '0', text: 'Not Active' },
      ],
      imageData: null,
      show: 'true',
      catData: {
        category_name: null,
        status: null,
        icon: null,
        color: null,
        description: null,
      },
    }
  },
  setup() {
    const OTHER_MODULE = 'othermodule'

    // Register module
    if (!store.hasModule(OTHER_MODULE)) store.registerModule(OTHER_MODULE, otherModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OTHER_MODULE)) store.unregisterModule(OTHER_MODULE)
    })

    const tagsData = ref([])
    const formData = reactive({
      tag_name: null,
    })

    const resolveUserStatusValue = status => {
      if (status === 1) return 'Active'
      if (status === 0) return 'Not Active'
      return 'primary'
    }
    const resolveUserStatusVariant = status => {
      if (status === 1) return 'success'
      if (status === 0) return 'warning'
      return 'primary'
    }
    const { tableColumns, donationTableColumns } = others()

    return {
      tagsData,
      tableColumns,
      formData,
      donationTableColumns,
      avatarText,
      resolveUserStatusValue,
      resolveUserStatusVariant,
    }
  },
  mounted() {
    // this.myProvider()
  },
  methods: {
    onChange() {
      // eslint-disable-next-line prefer-destructuring
      this.addCat.icon = this.$refs.file.files[0]
    },
    sendInfo(id) {
      this.selectedLanguage = id
    },
    myProvider(ctx) {
      const promis = axios.get(`http://entities-microsevice.muslimdo.com/api/internalops/donationcatergories/list/${ctx.currentPage}`)
      return promis.then(res => {
        console.log(res.data)
        const items = res.data.data.data
        // this.rows = res.data.data.data
        // this.currentPage = res.data.data.pagination.current_page
        this.totalRows = res.data.data.pagination.total
        return items
      // eslint-disable-next-line no-unused-vars
      }).finally(() => {
        this.loading = false
      // eslint-disable-next-line no-unused-vars
      }).catch(error => [])
    },
    addDonationCat() {
      const formData = new FormData()
      formData.append('category_name', this.addCat.category_name)
      formData.append('status', this.addCat.status)
      formData.append('color', this.addCat.color)
      formData.append('description', this.addCat.description)
      formData.append('icon', this.addCat.icon)
      axios.post('http://entities-microsevice.muslimdo.com/api/internalops/donationcatergories', this.addCat).then(res => {
        if (res.data.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Category Added Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.addCat = {}
          this.show = false
        }
      }).catch(err => {
        if (err.response.data.errors) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.category_name.toString(),
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
        console.log(err.response.data.errors)
      }).finally(() => {
        this.show = true
      })
    },
    deleteCategory(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      // eslint-disable-next-line consistent-return
      }).then(result => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          axios.delete(`http://entities-microsevice.muslimdo.com/api/internalops/donationcatergories/${id}`).then(res => {
            this.$swal(
              'Deleted!',
              'User has been deleted.',
              'success',
            )
            this.$refs.categoryTable.refresh()
          }).finally(() => {
            this.loading = false
          })
        } else if (result.dismiss) {
          return false
        }
      })
    },
    getCat(id) {
      axios.get(`http://entities-microsevice.muslimdo.com/api/internalops/donationcatergories/${id}`).then(res => {
        console.log(res.data)
        this.catData = res.data.data
      })
    },
    updateCat(id) {
      axios.post(`http://entities-microsevice.muslimdo.com/api/internalops/donationcatergories/${id}?_method=PUT`, this.catData)
        .then(res => {
          if (res.data.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Category Edited Succesfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            this.addCat = {}
            this.show = false
            this.$refs.categoryTable.refresh()
          }
        }).catch(err => {
          if (err.response.data.errors) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.errors.category_name.toString(),
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          console.log(err.response.data.errors)
        }).finally(() => {
          this.show = true
        })
    },
  },
}
</script>
